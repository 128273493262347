<template>
  <div class="signInRecord">
    <el-dialog
      top="35vh"
      :close-on-click-modal="false"
      class="recordEchart"
      :visible.sync="dialogVisible"
    >
      <div class="title">签到统计</div>
      <div class="close-icon" @click="dialogVisible = false">
        <i class="el-icon-close"></i>
      </div>
      <div class="body">
        <div class="item-title">签到总人数：{{ totalPeple }}人</div>
        <div class="content">
          <div id="main" ref="chart" style="width: 560px;height: 240px;"></div>
        </div>
      </div>
    </el-dialog>
    <table-list
      :title="tableTitle"
      :loading="loading"
      :search-form="searchForm(this)"
      :data="signInList"
      :btns="btns(this)"
      :columns="columns(this)"
      :pager="pager"
      @search="onSearch"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
  </div>
</template>
<script>
//表格标题
const tableTitle = '签到记录'
// 搜索框配置
const searchForm = _this => [
  {
    label: '预约场次',
    prop: 'reserveDetailId',
    type: 'select',
    width: 120,
    children: _this.SessionList.map(item => ({
      value: item.reserveDetailId,
      label: item.reserveTitle,
    })),
  },
  {
    label: '报名状态',
    type: 'select',
    width: 120,
    prop: 'registrationStatus',
    children: [
      { value: 0, label: '未报名' },
      { value: 1, label: '已报名' },
    ],
  },
  {
    prop: 'userName',
    label: '姓名',
  },
  {
    prop: 'phone',
    label: '手机号码',
  },
]
// 顶部按钮
const btns = _this => [
  {
    label: '签到统计',
    method: _this.handleStatistics,
  },
  {
    label: '导出',
    method: _this.handleexport,
    type: 'primary',
  },
]
// 表格列配置
const columns = () => [
  {
    prop: '',
    label: '',
    width: '45px',
  },
  {
    prop: 'userName',
    label: '姓名',
    minWidth: '130',
    formatter: row => {
      if (row.userName.length > 0 && row.userName.length < 8) {
        return row.userName
      } else if (row.userName.length > 8) {
        return row.userName.slice(0, 8) + '...'
      }
    },
  },
  {
    prop: 'phone',
    label: '手机号码',
    minWidth: '150',
  },
  {
    prop: 'trainingTime',
    label: '培训时间',
    minWidth: '265',
  },
  {
    prop: 'reserveDetailTitle',
    label: '培训场次',
    minWidth: '100px',
  },
  {
    prop: 'punchTime',
    label: '签到时间',
    minWidth: '200',
  },
  {
    prop: 'address',
    label: '签到地点',
    minWidth: '350',
  },
  {
    prop: 'ip',
    label: 'IP',
    minWidth: '150',
  },
]
import TableList from '@/components/TableList'
import to from 'await-to'
import { SessionList, signInPage, signListexport, signInStatistic } from '@/api/teacher'
import * as echarts from 'echarts'
export default {
  components: {
    TableList,
  },
  data() {
    return {
      reserveTeacherId: this.$route.query.id,
      loading: false,
      tableTitle,
      searchForm,
      columns,
      btns,
      signInList: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      queryParams: {},
      SessionList: [],
      dialogVisible: false,
      totalPeple: 0,
    }
  },
  mounted() {
    this.handleSessionList()
    this.handlesignInList()
  },
  methods: {
    async handleSessionList() {
      const [res, err] = await to(SessionList({ reserveTeacherId: this.reserveTeacherId }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.SessionList = res.data
    },
    async handlesignInList() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(
        signInPage({
          current,
          size,
          reserveTeacherId: this.reserveTeacherId,
          ...this.queryParams,
        }),
      )
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.loading = false
      this.pager.total = res.data.total
      this.signInList = res.data.list
    },
    //签到统计
    async handleStatistics() {
      const [res, err] = await to(signInStatistic({ reserveTeacherId: this.reserveTeacherId }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.totalPeple = res.data.total
      this.dialogVisible = true
      setTimeout(function() {
        var chartDom = document.getElementById('main')
        var myChart = echarts.init(chartDom)
        // 绘制图表
        var option = {
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)',
          },
          series: [
            {
              name: '',
              type: 'pie', //饼状图
              radius: '70%', //大小
              center: ['50%', '50%'], //显示位置
              data: res.data.data,
              color: ['#2D8BFD', '#61D9AC'],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)',
                },
              },
              itemStyle: {
                borderRadius: 5,
              },
              label: {
                show: true,
                textStyle: {
                  fontWeight: 400,
                  fontSize: 14, //文字的字体大小
                  color: '#333',
                  fontfamily: 'Source Han Sans CN, Source Han Sans CN-Regular',
                },
              },
              labelLine: {
                //指示线样式设置
                length: 25, //第一段指示线的长度
              },
            },
            {
              name: '',
              type: 'pie',
              radius: '70%',
              center: ['50%', '50%'],
              data: res.data.data,
              color: ['#2D8BFD', '#61D9AC'],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)',
                },
              },
              itemStyle: {
                borderRadius: 5,
              },
              label: {
                //此处为指示线文字
                show: true,
                position: 'inner', //标签的位置
                textStyle: {
                  fontWeight: 200,
                  fontSize: 10, //文字的字体大小
                  color: '#FFFFFF',
                },
                formatter: function(p) {
                  //指示线对应文字
                  var data = p.data
                  return data.value + '人'
                },
              },
              labelLine: {
                //指示线状态
                show: true,
                smooth: 0.2,
                length: 10,
                length2: 20,
              },
            },
          ],
        }
        myChart.setOption(option)
      }, 500)
    },
    //导出
    async handleexport() {
      const [res, err] = await to(
        signListexport({ reserveTeacherId: this.reserveTeacherId, ...this.queryParams }),
      )
      if (err) return this.$message.warning({ type: 'warning', message: err.msg })
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '签到记录.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.handlesignInList()
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.handlesignInList()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.handlesignInList()
    },
  },
}
</script>
<style lang="scss" scoped>
.signInRecord {
  ::v-deep.form {
    .el-button {
      width: 98px !important;
    }
    .el-select {
      width: 120px !important;
    }
  }
  ::v-deep.top-bar {
    padding: 19px 0 10px 0;
    .el-button--default {
      width: 110px !important;
    }
    .el-button--primary {
      width: 90px !important;
    }
  }
  ::v-deep.el-table {
    font-family: SourceHanSansCN-Normal;
  }
  .recordEchart {
    ::v-deep.el-dialog {
      position: relative;
      width: 560px;
      height: 350px;
      background: #ffffff;
      border-radius: 0;
      .close-icon {
        z-index: 99;
        cursor: pointer;
        position: absolute;
        right: 24px;
        top: 10px;
        font-size: 25px;
        font-weight: bold;
        color: #8c8c8c;
      }
      .el-dialog__header {
        display: none;
      }
      .el-tabs__header {
        margin-bottom: 15px;
        padding-top: 29px;
      }
      .el-dialog__body {
        padding: 0;
      }
      .body {
        box-sizing: border-box;
        padding: 9px 0px 20px 0px;
      }
      .content {
        margin-top: 10px;
        display: flex;
        justify-content: center;
      }
      .title {
        height: 55px;
        line-height: 55px;
        border-bottom: 1px solid #c9c9c9;
        padding-left: 34px;
        font-size: 18px;
        font-family: Source Han Sans CN, Source Han Sans CN-Regular;
        font-weight: 400;
      }
      .item-title {
        padding: 0 120px;
        color: #333;
        font-weight: 700;
        font-family: Source Han Sans CN, Source Han Sans CN-Regular;
      }
    }
  }
}
</style>
