<template>
  <div class="enrolRecord">
    <table-list
      :title="tableTitle"
      :spantext="text"
      :loading="loading"
      :search-form="searchForm(this)"
      :data="recordList"
      :btns="btns(this)"
      :columns="columns(this)"
      :pager="pager"
      @search="onSearch"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
  </div>
</template>
<script>
//表格标题
const tableTitle = '报名记录'
const text = ''
// 搜索框配置
const searchForm = () => [
  {
    label: '姓名',
    prop: 'userName',
  },
  {
    label: '手机号码',
    prop: 'phone',
  },
]
// 顶部按钮
const btns = _this => [
  {
    label: '导出',
    method: _this.handleexport,
    type: 'primary',
  },
]
// 表格列配置
const columns = () => [
  {
    prop: '',
    label: '',
    width: '45px',
  },
  {
    prop: 'userName',
    label: '姓名',
    minWidth: '130',
    formatter: row => {
      if (row.userName.length > 0 && row.userName.length < 22) {
        return row.userName
      } else if (row.userName.length > 22) {
        return row.userName.slice(0, 22) + '...'
      }
    },
  },
  {
    prop: 'phone',
    label: '手机号码',
    minWidth: '150',
  },
  {
    label: '培训时间',
    minWidth: '350px',
    formatter: row => {
      return row.trainingBeginTime + ' - ' + row.trainingEndTime
    },
  },
  {
    prop: 'registrationTime',
    label: '报名时间',
    minWidth: '200px',
  },
]
import TableList from '@/components/TableList'
import to from 'await-to'
import { reservePage, reserveCount, reserveExport } from '@/api/teacher'
export default {
  components: {
    TableList,
  },
  data() {
    return {
      reserveTeacherId: this.$route.query.id,
      loading: false,
      tableTitle,
      text,
      numTotal: 0,
      searchForm,
      columns,
      btns,
      recordList: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      queryParams: {},
    }
  },
  created() {
    this.handleGetreserveCount()
  },
  mounted() {
    this.handleGetreserveList()
  },
  methods: {
    async handleGetreserveList() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(
        reservePage({
          current,
          size,
          reserveTeacherId: this.reserveTeacherId,
          ...this.queryParams,
        }),
      )
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.loading = false
      this.pager.total = res.data.total
      this.recordList = res.data.list
    },
    async handleGetreserveCount() {
      const [res, err] = await to(reserveCount({ reserveTeacherId: this.reserveTeacherId }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.numTotal = res.data
      this.text = '当前总报名数：' + this.numTotal
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.handleGetreserveList()
    },
    //导出按钮
    async handleexport() {
      const [res, err] = await to(
        reserveExport({ reserveTeacherId: this.reserveTeacherId, ...this.queryParams }),
      )
      if (err) return this.$message.warning({ type: 'warning', message: err.msg })
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '报名记录.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.handleGetreserveList()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.handleGetreserveList()
    },
  },
}
</script>
<style lang="scss" scoped>
.enrolRecord {
  ::v-deep.form {
    .el-button {
      width: 98px !important;
    }
  }
  ::v-deep.top-bar {
    .el-button {
      width: 90px !important;
    }
  }
  ::v-deep.el-table {
    font-family: SourceHanSansCN-Normal;
  }
}
</style>
