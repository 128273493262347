<template>
  <div class="recordInfo">
    <el-tabs v-model="activeName">
      <el-tab-pane label="报名记录" name="1">
        <RecordByenrol></RecordByenrol>
      </el-tab-pane>
      <el-tab-pane label="签到记录" name="2">
        <RecordBysignIn></RecordBysignIn>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import RecordByenrol from './components/enrolRecord'
import RecordBysignIn from './components/signInRecord'
export default {
  name: 'RecordInfo',
  components: {
    RecordByenrol,
    RecordBysignIn,
  },
  data() {
    return {
      activeName: '1',
    }
  },
}
</script>
<style lang="scss" scoped>
.recordInfo {
  ::v-deep.el-tabs {
    .el-tabs__header {
      font-size: 16px;
      color: #ff7b33;
      line-height: 16px;
      margin-bottom: 20px;
      .el-tabs__nav {
        height: 16px;
        padding-bottom: 7px;
        .el-tabs__item {
          font-size: 16px;
          height: 16px;
          line-height: 16px;
          font-family: SourceHanSansCN-Regular;
        }
      }
    }
  }
}
</style>
